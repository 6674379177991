import { useEffect, useState } from "react";
import { axiosContext, UseAppContext } from "../../context/appContext";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import IResponse from "../../interfaces/IResponse";
import { toast } from "react-toastify";
import IDiscountCode from "../../interfaces/checkout/IDiscountCode";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/actionTypes";
import { fetchUser } from "../../store/userReducer";
import { useNavigate } from "react-router-dom";

export default function CheckoutForm() {
  const { translate } = UseAppContext();
  const [selectedPlan, setSelectedPlan] = useState(1);
  const [code, setCode] = useState("");
  const [userId, setUserId] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null as any);
  const [discount, setDiscount] = useState({} as IDiscountCode);
  const [loading, setLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const plans = [
    {
      plan: translate("checkout.plan0.plan"),
      title: translate("checkout.plan0.title"),
      clients: translate("checkout.plan0.clients"),
      workouts: translate("checkout.plan0.workouts"),
      price: translate("checkout.plan0.price"),
    },
    {
      plan: translate("checkout.plan1.plan"),
      title: translate("checkout.plan1.title"),
      clients: translate("checkout.plan1.clients"),
      workouts: translate("checkout.plan1.workouts"),
      price: translate("checkout.plan1.price"),
    },
    {
      plan: translate("checkout.plan2.plan"),
      title: translate("checkout.plan2.title"),
      clients: translate("checkout.plan2.clients"),
      workouts: translate("checkout.plan2.workouts"),
      price: translate("checkout.plan2.price"),
    },
  ];

  useEffect(() => {
    if (code.length > 4) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      const timeout = setTimeout(() => {
        getDiscountCode();
      }, 500);

      setTypingTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  async function getDiscountCode() {
    if (!code) {
      return;
    }
    try {
      setLoading(true);
      const response = await axiosContext.get(
        `/app/checkout/discountCode?code=${code.toLowerCase()}`
      );
      const data = response.data as IResponse;
      if (data.success && data?.data?.status === "active") {
        setDiscount(data.data);
        toast.success(translate(data.info), {
          position: "top-center",
          autoClose: 3000,
          toastId: "infoCode",
        });
      } else {
        toast.error(translate(data.info), {
          position: "top-center",
          autoClose: 3000,
          toastId: "infoCode",
        });
        setDiscount({} as IDiscountCode);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(translate(error), {
        position: "top-center",
        autoClose: 3000,
        toastId: "infoCode",
      });
      setDiscount({} as IDiscountCode);
    }
  }

  function planActionValidation() {
    if (selectedPlan > 0) {
      buyPlan();
    } else {
      buyFreePlan();
    }
  }

  async function buyFreePlan() {
    try {
      setLoading(true);
      const response = await axiosContext.post(`/app/checkout/buyFreePlan`);
      const data = response.data as IResponse;
      if (data.success) {
        dispatch(fetchUser());
        toast.success(translate(data.info), {
          position: "top-center",
          autoClose: 3000,
          toastId: "infoCode",
        });
        navigate("/");
      } else {
        toast.error(translate(data.info), {
          position: "top-center",
          autoClose: 3000,
          toastId: "infoCode",
        });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(translate(error), {
        position: "top-center",
        autoClose: 3000,
        toastId: "infoCode",
      });
      setDiscount({} as IDiscountCode);
    }
  }

  async function buyPlan() {
    try {
      setLoading(true);
      const response = await axiosContext.post(`/app/checkout/buyPlan`, {
        plan: plans[selectedPlan].plan,
        discount: discount.code,
        userId,
      });
      const data = response.data as IResponse;
      if (data.success) {
        dispatch(fetchUser());
        toast.success(translate(data.info), {
          position: "top-center",
          autoClose: 3000,
          toastId: "infoCode",
        });
        navigate("/");
      } else {
        toast.error(translate(data.info), {
          position: "top-center",
          autoClose: 3000,
          toastId: "infoCode",
        });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(translate(error), {
        position: "top-center",
        autoClose: 3000,
        toastId: "infoCode",
      });
      setDiscount({} as IDiscountCode);
    }
  }

  return (
    <div className="m-auto pt-3">
      {<h4>{translate("ourPlans")}</h4>}

      <Row className="mx-2 pt-2">
        <Col className="ps-0 pe-1">
          <div
            className={
              "border rounded p-2 my-1 pointer border-2 " +
              (selectedPlan === 0 ? "border-success " : "")
            }
            onClick={() => {
              setSelectedPlan(0);
            }}
          >
            <h5>{plans[0].title}</h5>
            <p className="mb-1">{plans[0].clients}</p>
            <p className="mb-1">{plans[0].workouts}</p>
            <p className="mb-2">{plans[0].price}</p>
            <Button size="sm" variant="outline-primary">
              {translate("choose")}
            </Button>
          </div>
        </Col>
        <Col className="pe-0 ps-1">
          <div
            className={
              "border rounded p-2 my-1 pointer border-2 " +
              (selectedPlan === 1 ? "border-success " : "")
            }
            onClick={() => {
              setSelectedPlan(1);
            }}
          >
            <h5>{plans[1].title}</h5>
            <p className="mb-1">{plans[1].clients}</p>
            <p className="mb-1">{plans[1].workouts}</p>
            <p className="mb-2">{plans[1].price}</p>
            <Button size="sm" variant="outline-primary">
              {translate("choose")}
            </Button>
          </div>
        </Col>
      </Row>

      <div className="border rounded p-2 mx-2 mt-3">
        <h4>{translate("checkout.checkout")}</h4>
        <h5>{plans[selectedPlan].title}</h5>
        <p className="mb-1">
          <strong>{plans[selectedPlan].price}</strong>
        </p>

        {selectedPlan > 0 && (
          <Form.Group controlId="userId" className="mx-4">
            <Form.Label className="mb-1">
              {translate("userIdRequired")}{" "}
              {loading && <Spinner size="sm" className="ms-2" />}
            </Form.Label>
            <Form.Control
              disabled={loading}
              type="text"
              name="userId"
              required
              value={userId}
              onChange={(e) => {
                setUserId(e.currentTarget.value || "");
              }}
            />
          </Form.Group>
        )}

        {selectedPlan > 0 && (
          <Form.Group controlId="code" className="mx-4">
            <Form.Label className="mb-1">
              {translate("promotionalCode")}{" "}
              {loading && <Spinner size="sm" className="ms-2" />}
            </Form.Label>
            <Form.Control
              disabled={loading}
              type="text"
              name="code"
              value={code}
              onChange={(e) => {
                setCode(e.currentTarget.value || "");
              }}
            />
            {code && discount?.info && <small>{discount.info}</small>}
          </Form.Group>
        )}

        <Button
          size="sm"
          className="mt-2"
          disabled={loading || (selectedPlan > 0 && userId?.length < 11)}
          onClick={() => {
            planActionValidation();
          }}
        >
          {translate("hire")}
        </Button>
      </div>
    </div>
  );
}
