import { useEffect, useState } from "react";
import { axiosContext, UseAppContext } from "../../context/appContext";
import IClient from "../../interfaces/IClient";
import { useDispatch } from "react-redux";
import { setStoreClient } from "../../store/clientReducer";
import { AppDispatch } from "../../store/actionTypes";
import ClientModal from "./ClientModal";
import { Button, Image, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LinkModal from "./link/LinkModal";
import Logo from "../../assets/brand/logo-fallback.png";
import { FaDumbbell, FaEdit, FaLock, FaShare, FaUnlock } from "react-icons/fa";
import { toast } from "react-toastify";
import IResponse from "../../interfaces/IResponse";
import "./clientCard.scss";

interface Props {
  client: IClient;
  forceImage?: boolean;
}

export default function ClientCard({ client, forceImage }: Props) {
  const { translate, isMobile } = UseAppContext();
  const dispatch: AppDispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const navigate = useNavigate();
  const [selectedClient, setSelectedClient] = useState(client);
  const [confirmBlock, setConfirmBlock] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (confirmBlock) {
      const timer = setTimeout(() => {
        setConfirmBlock(false);
      }, 5000);

      if (isMobile) {
        toast.info(translate("confirmClick"), {
          position: "top-center",
          autoClose: 4000,
          toastId: "infoConfirm",
        });
      }

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmBlock]);

  function openEditClient() {
    setShow(true);
  }

  function linkClient() {
    setShowLink(true);
  }

  function selectClient() {
    dispatch(setStoreClient(client));
    navigate(`/client/${selectedClient.id}`);
  }

  async function editClient() {
    try {
      const newClient = { ...selectedClient } as IClient;
      newClient.active = !Boolean(newClient.active);

      const response = await axiosContext.post(
        "/app/clients/updateClient",
        newClient
      );

      if (!response?.data) {
        toast.error(translate("error"), {
          position: "top-center",
          autoClose: 8000,
        });
        return;
      }

      const responseData = response?.data as IResponse;

      if (responseData.success) {
        toast.success(translate("successEdit"), {
          position: "top-center",
          autoClose: 1500,
        });

        setSelectedClient({ ...newClient });
      } else {
        toast.error(translate(responseData.info), {
          position: "top-center",
          autoClose: 8000,
        });
      }
      setLoading(false);
    } catch (e: any) {
      console.error(e);
      toast.error(e.toString(), {
        position: "top-center",
        autoClose: 8000,
      });
      setLoading(false);
    } finally {
      setConfirmBlock(false);
    }
  }

  return (
    <div>
      <div
        className={
          "border rounded p-2 text-start mt-1 d-flex bg-white " +
          (isMobile ? "flex-column" : "")
        }
        key={selectedClient.id}
      >
        {(!isMobile || forceImage) && (
          <div
            className={
              isMobile
                ? "mx-auto " + (selectedClient.pic ? "mb-2" : "")
                : "me-3"
            }
          >
            <Image
              src={selectedClient.pic || Logo}
              width={isMobile ? 70 : 100}
              height={isMobile ? undefined : 100}
              className={
                "rounded " + (selectedClient.pic ? "" : "border bg-secondary")
              }
            />
          </div>
        )}
        <div className="d-flex flex-column">
          {!selectedClient.active && (
            <h5 className="text-center">- {translate("blockedClient")} -</h5>
          )}

          <h5 className="mb-0">{selectedClient.name}</h5>
          <p className="mb-0 small">
            {selectedClient.gender && (
              <span>{translate(selectedClient.gender || "male")}</span>
            )}
            {selectedClient.age && (
              <span>
                , {selectedClient.age} {translate("years")}
              </span>
            )}
            {selectedClient.weight && (
              <span>
                , {selectedClient.weight} {translate("kg")}
              </span>
            )}
          </p>

          {selectedClient.goals && (
            <p className="mb-0 small">
              {translate("goals")}: {selectedClient.goals}
            </p>
          )}

          <div
            className={
              "mb-0 mt-auto " +
              (isMobile ? "client-card-actions-mobile" : "client-card-actions")
            }
          >
            {selectedClient.active && (
              <>
                <Button
                  variant="primary"
                  size="sm"
                  className={isMobile ? "m-1" : "me-1 my-1"}
                  onClick={() => {
                    selectClient();
                  }}
                >
                  <FaDumbbell className={isMobile ? "" : "me-2"} />
                  {!isMobile && translate("trainings")}
                </Button>

                {!selectedClient.user && (
                  <Button
                    variant="primary"
                    size="sm"
                    className="m-1"
                    onClick={() => {
                      linkClient();
                    }}
                  >
                    <FaShare className={isMobile ? "" : "me-2"} />
                    {!isMobile && translate("connect")}
                  </Button>
                )}

                <Button
                  variant="primary"
                  size="sm"
                  className="m-1"
                  onClick={() => {
                    openEditClient();
                  }}
                >
                  <FaEdit className={isMobile ? "" : "me-2"} />
                  {!isMobile && translate("edit")}
                </Button>
              </>
            )}

            <Button
              variant={confirmBlock ? "danger" : "primary"}
              size="sm"
              className="m-1"
              disabled={loading}
              onClick={() => {
                if (confirmBlock) {
                  editClient();
                } else {
                  setConfirmBlock(true);
                }
              }}
            >
              {loading && <Spinner size="sm" className="me-2" />}

              {selectedClient.active ? (
                <span>
                  <FaLock className={isMobile ? "" : "me-2"} />
                  {!isMobile &&
                    (confirmBlock
                      ? translate("confirmBlock")
                      : translate("blockClient"))}
                </span>
              ) : (
                <span>
                  <FaUnlock className={isMobile ? "" : "me-2"} />
                  {confirmBlock
                    ? translate("confirmUnlock")
                    : translate("unlockClient")}
                </span>
              )}
            </Button>
          </div>
        </div>
      </div>

      <ClientModal
        show={show}
        hide={() => {
          setShow(false);
        }}
        currentClient={selectedClient}
        updateClient={(val: IClient) => setSelectedClient(val)}
      />

      <LinkModal
        show={showLink}
        hide={() => {
          setShowLink(false);
        }}
        currentClient={selectedClient}
      />
    </div>
  );
}
