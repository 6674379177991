import { UseAppContext } from "../../../context/appContext";
import IIntervals from "../../../interfaces/workouts/IIntervals";
import WorkoutExcerciceInterval from "./WorkoutExcerciceInterval";

interface props {
  intervals?: Array<IIntervals>;
}

export default function WorkoutExcerciceIntervals({ intervals }: props) {
  const { translate } = UseAppContext();

  if (!intervals || !intervals?.length) {
    return null;
  }

  return (
    <div>
      <h6 className="mb-0 mt-2">{translate("intervals")}</h6>
      <div className="border ps-1 pt-2 pe-2">
        {intervals?.map((interval, position) => (
          <WorkoutExcerciceInterval
            interval={interval}
            key={position + interval.name}
          />
        ))}
      </div>
    </div>
  );
}
