import { useSelector } from "react-redux";
import { UseAppContext } from "../../../context/appContext";
import ISubscription from "../../../interfaces/subscriptions/ISubscription";
import SubscriptionCard from "./SubscriptionCard";
import { Spinner } from "react-bootstrap";

export default function SubcriptionsList() {
  const { isMobile, translate } = UseAppContext();

  const subcriptions = useSelector(
    (state: any) => state.user.subcriptions
  ) as Array<ISubscription>;

  const loading = useSelector(
    (state: any) => state.user.loadingSubcriptions
  ) as boolean;

  return (
    <div
      className={
        "border bg-white rounded p-2 border " + (isMobile ? "mt-3" : "")
      }
    >
      <h5>{translate("subcriptions")}</h5>

      {loading && <Spinner size="sm" className="ma-auto" />}

      {!loading && subcriptions?.length < 1 && (
        <p>{translate("noSubcriptionsYet")}</p>
      )}

      {subcriptions?.map((subcription) => {
        return (
          <SubscriptionCard subcription={subcription} key={subcription.id} />
        );
      })}
    </div>
  );
}
