import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { UseAppContext } from "../context/appContext";
import AppNavbar from "../components/navbar/AppNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store/actionTypes";
import { fetchClients } from "../store/clientReducer";
import IClient from "../interfaces/IClient";
import {
  fetchPayments,
  fetchPersonalProfile,
  fetchSubscritions,
  fetchUser,
} from "../store/userReducer";
import IUser from "../interfaces/users/IUser";
import { Spinner } from "react-bootstrap";
import { fetchUserWorkouts } from "../store/workoutReducer";
import IWorkoutResponse from "../interfaces/workouts/IWorkoutResponse";
import IProfile from "../interfaces/users/IProfile";
import SubcribeModal from "../components/clients/subscribe/SubcribeModal";

interface Props {
  children: React.ReactNode;
}

function AppContainer({ children }: Props) {
  const location = useLocation();
  const { isMobile, loadingSession, isLogged, setUser, user } = UseAppContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const professor = searchParams.get("professor");
  const pin = searchParams.get("pin");
  const dispatch: AppDispatch = useDispatch();
  const [times, setTimes] = useState(0);
  const [showSubcribe, setShowSubscribe] = useState(false);
  const [isProfessor, setIsProfessor] = useState(false);
  const clients = useSelector(
    (state: any) => state.client.clients
  ) as Array<IClient>;
  const workouts = useSelector(
    (state: any) => state.workout.workouts
  ) as Array<IWorkoutResponse>;
  const storedUser = useSelector((state: any) => state.user.user) as IUser;
  const loadedProfile = useSelector(
    (state: any) => state.user.loadedProfile
  ) as boolean;
  const loadingSubcriptions = useSelector(
    (state: any) => state.user.loadingSubcriptions
  ) as boolean;
  const profile = useSelector(
    (state: any) => state.user.personalProfile
  ) as IProfile;

  useEffect(() => {
    if (!loadingSession && user.id && !profile.email && times === 0) {
      setTimes(1);
      dispatch(fetchPersonalProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loadingSession, profile]);

  useEffect(() => {
    function validProfessorLink() {
      if (!user) return false;
      if (user.type && user.type !== "professor") return true;
      if (professor && storedUser?.link && storedUser.link !== professor)
        return true;
      return false;
    }

    if (!loadingSession && professor && pin && validProfessorLink()) {
      if (storedUser.link !== professor) {
        setShowSubscribe(true);
      }
    } else {
      setShowSubscribe(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professor, loadingSession, pin, storedUser, user]);

  useEffect(() => {
    setIsProfessor(storedUser?.type === "professor");

    if (storedUser && storedUser.id) {
      const newUser = { ...storedUser } as IUser;
      if (user.token) {
        newUser.token = user.token;
      }
      setUser(newUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedUser]);

  useEffect(() => {
    if (loadedProfile && user.id && !isProfessor && !loadingSubcriptions) {
      dispatch(fetchSubscritions());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfessor, loadedProfile]);

  useEffect(() => {
    if (user.id && (!workouts || !workouts.length)) {
      dispatch(fetchUserWorkouts());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, storedUser]);

  useEffect(() => {
    if (
      !loadingSession &&
      !isLogged &&
      location.pathname !== "/" &&
      location.pathname !== "/terms" &&
      location.pathname !== "/subscribe"
    ) {
      navigate(`/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSession, isLogged]);

  useEffect(() => {
    if (isLogged && user.type === "professor" && !clients.length) {
      dispatch(fetchClients());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, user]);

  useEffect(() => {
    if (isLogged) {
      dispatch(fetchUser());
      dispatch(fetchPayments());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  return (
    <div
      className="main-container d-flex bg-grey text-center flex-column"
      style={{
        width: "100vw",
        minHeight: "100vh",
        top: "0px",
        left: "0px",
        overflow: "auto",
      }}
    >
      <AppNavbar />
      <div
        className="mx-auto d-flex flex-column"
        style={{
          maxWidth: isMobile ? "100vw" : "700px",
          width: "100%",
          minHeight: "calc(100vh - 59px)",
          marginTop: "0px",
          paddingBottom: "50px",
          zIndex: "20",
        }}
      >
        {!loadedProfile && isLogged ? (
          <Spinner size="sm" className="m-auto" />
        ) : (
          children
        )}
      </div>
      <SubcribeModal
        show={showSubcribe}
        hide={() => {
          setShowSubscribe(false);
        }}
      />
    </div>
  );
}

export default AppContainer;
