import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import userReducer from "./userReducer";
import clientReducer from "./clientReducer";
import workoutReducer from "./workoutReducer";

export const store = configureStore({
  reducer: combineReducers({
    user: userReducer,
    client: clientReducer,
    workout: workoutReducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
