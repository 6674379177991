import { useLocation, useNavigate } from "react-router-dom";
import { UseAppContext } from "../../context/appContext";
import { Button } from "react-bootstrap";
import { FaChevronLeft } from "react-icons/fa";
import { MdLogout } from "react-icons/md";

export default function AppNavbar() {
  const { translate, isLogged, logout } = UseAppContext();
  const location = useLocation();
  const canBack = location.pathname !== "/";
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  return (
    <div
      className="text-center bg-primary d-flex text-white"
      style={{ minHeight: "40px" }}
    >
      {canBack ? (
        <Button
          variant="primary"
          onClick={() => {
            goBack();
          }}
          size="sm"
          className="d-flex mt-2"
          style={{ maxHeight: "28px" }}
        >
          <FaChevronLeft color="var(--text)" className="m-auto" />
        </Button>
      ) : (
        <div style={{ width: "32px" }} />
      )}

      <p className="m-auto">{translate("appName")}</p>

      {isLogged && (
        <Button
          variant="primary"
          onClick={() => {
            logout();
          }}
          size="sm"
          className="d-flex mt-2"
          style={{ maxHeight: "28px" }}
        >
          <MdLogout color="var(--text)" className="m-auto" size={20} />
        </Button>
      )}
    </div>
  );
}
