import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "./actionTypes";
import IUser from "../interfaces/users/IUser";
import { axiosContext } from "../context/appContext";
import IProfile from "../interfaces/users/IProfile";
import ISubscription from "../interfaces/subscriptions/ISubscription";

const initialState: UserState = {
  loadedProfile: false,
  user: {} as IUser,
  personalProfile: {} as IProfile,
  professionalProfile: {} as IProfile,
  subcriptions: [] as ISubscription[],
  loadingSubcriptions: false,
};

export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  try {
    const response = await axiosContext.get("/app/auth/verifyUser");
    if (response.data?.success) {
      const newProfile = response.data?.data as IUser;
      return newProfile;
    }
    return {} as IUser;
  } catch (error) {
    console.error("Error fetching data:", error);
    return {} as IUser;
  }
});

export const fetchPayments = createAsyncThunk(
  "user/fetchPayments",
  async () => {
    try {
      const response = await axiosContext.get("/app/auth/verifyPayments");
      if (response.data?.success) {
        const newProfile = response.data?.data as IUser;
        return newProfile;
      }
      return {} as IUser;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {} as IUser;
    }
  }
);

export const fetchPersonalProfile = createAsyncThunk(
  "user/fetchPersonalProfile",
  async () => {
    try {
      const response = await axiosContext.get("/app/user/fetchPersonalProfile");
      if (response.data?.success) {
        const newProfile = response.data?.data as IProfile;
        return newProfile;
      }
      return {} as IProfile;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {} as IProfile;
    }
  }
);

export const fetchProfessionalProfile = createAsyncThunk(
  "user/fetchProfessionalProfile",
  async () => {
    try {
      const response = await axiosContext.get(
        "/app/user/fetchProfessionalProfile"
      );
      if (response.data?.success) {
        const newProfile = response.data?.data as IProfile;
        return newProfile;
      }
      return {} as IProfile;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {} as IProfile;
    }
  }
);

export const fetchSubscritions = createAsyncThunk(
  "user/fetchSubscritions",
  async () => {
    try {
      const response = await axiosContext.get(
        "/app/subcription/getSubscriptions"
      );
      if (response.data?.success) {
        const subscriptions = response.data?.data as Array<ISubscription>;
        return subscriptions;
      }
      return [] as Array<ISubscription>;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [] as Array<ISubscription>;
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setStoreUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setPersonalProfile: (state, action: PayloadAction<IProfile>) => {
      state.personalProfile = action.payload;
      state.loadedProfile = Boolean(action.payload.id || action.payload.name);
    },
    setProfessionalProfile: (state, action: PayloadAction<IProfile>) => {
      state.professionalProfile = action.payload;
      state.loadedProfile = Boolean(action.payload.id || action.payload.name);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubscritions.pending, (state) => {
      state.loadingSubcriptions = true;
    });
    builder.addCase(fetchSubscritions.fulfilled, (state, action) => {
      state.subcriptions = action.payload;
      state.loadingSubcriptions = false;
    });
    builder.addCase(fetchSubscritions.rejected, (state) => {
      state.loadingSubcriptions = false;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(fetchPayments.fulfilled, () => {});
    builder.addCase(fetchPersonalProfile.fulfilled, (state, action) => {
      state.personalProfile = action.payload;
      state.loadedProfile = Boolean(action.payload.id || action.payload.name);
    });
    builder.addCase(fetchProfessionalProfile.fulfilled, (state, action) => {
      state.professionalProfile = action.payload;
      state.loadedProfile = Boolean(action.payload.id || action.payload.name);
    });
  },
});

export const { setStoreUser, setPersonalProfile, setProfessionalProfile } =
  userSlice.actions;
export default userSlice.reducer;
