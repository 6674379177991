import { Button, Modal } from "react-bootstrap";
import LinkForm from "./LinkForm";
import IClient from "../../../interfaces/IClient";
import { UseAppContext } from "../../../context/appContext";

interface props {
  show: boolean;
  hide: () => void;
  currentClient?: IClient;
}

export default function LinkModal({ show, hide, currentClient }: props) {
  const { translate } = UseAppContext();

  return (
    <Modal
      show={show}
      onHide={() => {
        hide();
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-none"
    >
      <Modal.Body>
        <LinkForm currentClient={currentClient} hide={hide} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hide} variant="secondary">
          {translate("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
