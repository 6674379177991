import { Form } from "react-bootstrap";
import { axiosContext, UseAppContext } from "../../context/appContext";
import { useEffect, useState } from "react";
import IClient from "../../interfaces/IClient";
import { FormEvent } from "../../types/FormEvent";
import { toast } from "react-toastify";
import IResponse from "../../interfaces/IResponse";
import { useDispatch } from "react-redux";
import { fetchClients } from "../../store/clientReducer";
import { AppDispatch } from "../../store/actionTypes";

interface props {
  currentClient?: IClient;
  hide: () => void;
  action: boolean;
  updateClient?: (client: IClient) => void;
}

export default function ClientForm({
  currentClient,
  hide,
  action,
  updateClient,
}: props) {
  const { translate } = UseAppContext();
  const [client, setClient] = useState(currentClient || ({} as IClient));
  const editing = Boolean(currentClient);
  const dispatch: AppDispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (action) {
      manageAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const handleClientChange = (e: FormEvent) => {
    const { name, value } = e.target;
    setClient((prevClient) => ({
      ...prevClient,
      [name]: value,
    }));
  };

  function manageAction() {
    setLoading(true);
    if (editing) {
      return editClient();
    }
    return createClient();
  }

  async function editClient() {
    try {
      const response = await axiosContext.post(
        "/app/clients/updateClient",
        client
      );

      if (!response?.data) {
        toast.error(translate("error"), {
          position: "top-center",
          autoClose: 8000,
        });
        return;
      }

      const responseData = response?.data as IResponse;

      if (responseData.success) {
        toast.success(translate("successEdit"), {
          position: "top-center",
          autoClose: 8000,
        });
        dispatch(fetchClients());
        if (updateClient) {
          updateClient(client);
        }
        hide();
      } else {
        toast.error(translate(responseData.info), {
          position: "top-center",
          autoClose: 8000,
        });
      }
      setLoading(false);
    } catch (e: any) {
      toast.error(e.toString(), {
        position: "top-center",
        autoClose: 8000,
      });
      setLoading(false);
    }
  }

  async function createClient() {
    try {
      const response = await axiosContext.post(
        "/app/clients/createClient",
        client
      );

      if (!response?.data) {
        toast.error(translate("error"), {
          position: "top-center",
          autoClose: 8000,
        });
        return;
      }

      const responseData = response?.data as IResponse;

      if (responseData.success) {
        toast.success(translate("successCreate"), {
          position: "top-center",
          autoClose: 8000,
        });
        dispatch(fetchClients());
        hide();
      } else {
        toast.error(translate(responseData.info), {
          position: "top-center",
          autoClose: 8000,
        });
        hide();
      }
      setLoading(false);
    } catch (e: any) {
      console.error(e);
      toast.error(e.toString(), {
        position: "top-center",
        autoClose: 8000,
      });
      setLoading(false);
    }
  }

  return (
    <div>
      <Form className="mb-4 mx-2 mt-2">
        <Form.Group controlId="name">
          <Form.Label>{translate("name")}</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={client.name || ""}
            onChange={handleClientChange}
            required
            isInvalid={!Boolean(client.name)}
          />
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>{translate("email")}</Form.Label>
          <Form.Control
            type="text"
            name="email"
            value={client.email || ""}
            onChange={handleClientChange}
          />
        </Form.Group>

        {editing && (
          <Form.Group controlId="pin">
            <Form.Label>{translate("pin")}</Form.Label>
            <Form.Control
              type="text"
              name="link"
              value={client.pin || ""}
              readOnly
            />
          </Form.Group>
        )}

        <Form.Group controlId="gender">
          <Form.Label>{translate("gender")}</Form.Label>
          <Form.Control
            type="text"
            name="gender"
            placeholder={translate("genderInfo")}
            value={client.gender || ""}
            onChange={handleClientChange}
          />
        </Form.Group>

        <Form.Group controlId="age">
          <Form.Label>{translate("ageYears")}</Form.Label>
          <Form.Control
            type="number"
            name="age"
            value={client.age || ""}
            onChange={handleClientChange}
          />
        </Form.Group>

        <Form.Group controlId="weight">
          <Form.Label>{translate("weightKg")}</Form.Label>
          <Form.Control
            type="number"
            name="weight"
            value={client.weight || ""}
            onChange={handleClientChange}
          />
        </Form.Group>

        <Form.Group controlId="fitnessLevel">
          <Form.Label>{translate("fitnessInfo")}</Form.Label>
          <Form.Control
            type="text"
            name="fitnessLevel"
            placeholder={translate("fitnessExample")}
            value={client.fitnessLevel || ""}
            onChange={handleClientChange}
          />
        </Form.Group>

        <Form.Group controlId="goals">
          <Form.Label>{translate("goals")}</Form.Label>
          <Form.Control
            type="text"
            name="goals"
            placeholder={translate("goalsExample")}
            value={client.goals || ""}
            onChange={handleClientChange}
          />
        </Form.Group>

        <Form.Group controlId="limitations">
          <Form.Label>{translate("limitations")}</Form.Label>
          <Form.Control
            type="text"
            name="limitations"
            as="textarea"
            placeholder={translate("limitationsInfo")}
            value={client.limitations || ""}
            onChange={handleClientChange}
          />
        </Form.Group>

        <Form.Group controlId="info">
          <Form.Label>{translate("infos")}</Form.Label>
          <Form.Control
            type="text"
            name="info"
            as="textarea"
            placeholder={translate("extraInfo")}
            value={client.info || ""}
            onChange={handleClientChange}
          />
        </Form.Group>
      </Form>
    </div>
  );
}
