import { Button } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import { useNavigate, useParams } from "react-router-dom";
import { FaCaretRight } from "react-icons/fa";
import IWorkoutResponse from "../../../interfaces/workouts/IWorkoutResponse";

interface props {
  currentWorkout: IWorkoutResponse;
}

export default function WorkoutSimpleCard({ currentWorkout }: props) {
  const { translate } = UseAppContext();
  const navigate = useNavigate();
  const { professor } = useParams();

  function goToWorkout() {
    if (professor) {
      return navigate(`/subscriptions/${professor}/${currentWorkout.id}`);
    }

    if (currentWorkout.client) {
      return navigate(`/client/${currentWorkout.client}/${currentWorkout.id}`);
    }

    navigate(`/workout/${currentWorkout.id}`);
  }

  return (
    <div
      className={
        "rounded p-2 text-start mt-1 d-flex pointer border size-hover border-gray bg-white"
      }
      onClick={goToWorkout}
    >
      <div
        className={
          "d-flex w-100 px-2 flex-column " +
          (currentWorkout.status === "DRAFT" ? "text-danger" : "")
        }
      >
        <p className="mb-0">
          <strong>{currentWorkout.title}</strong>
        </p>
        <p className="mb-0">
          {translate(currentWorkout.status)} -{" "}
          {currentWorkout.date?.toLocaleDateString()}
        </p>
      </div>
      <div className="d-flex">
        <Button className="my-auto" size="sm" onClick={goToWorkout}>
          <span className="m-auto">
            <FaCaretRight />
          </span>
        </Button>
      </div>
    </div>
  );
}
