import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import Home from "./pages/Home";
import { HelmetProvider } from "react-helmet-async";
import { I18nextProvider } from "react-i18next";
import i18n from "./locales/i18n";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AppProvider } from "./context/appContext";
import { Provider } from "react-redux";
import store from "./store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";
import Clients from "./pages/Clients";
import WorkoutPage from "./pages/WorkoutPage";
import Client from "./pages/Client";
import SubscriptionPage from "./pages/SubscriptionPage";
import Checkout from "./pages/Checkout";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const helmetContext = {};

root.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <I18nextProvider i18n={i18n}>
        <AppProvider>
          <Provider store={store}>
            <RouterProvider
              router={createBrowserRouter([
                { path: "*", element: <Home /> },
                { path: "/clients", element: <Clients /> },
                { path: "/checkout", element: <Checkout /> },
                { path: "/client/:client", element: <Client /> },
                { path: "/client/:client/:workout", element: <WorkoutPage /> },
                { path: "/subscription", element: <Home /> },
                {
                  path: "/subscriptions/:professor/",
                  element: <SubscriptionPage />,
                },
                {
                  path: "/subscriptions/:professor/:workout",
                  element: <WorkoutPage />,
                },
                { path: "/workout/:workout", element: <WorkoutPage /> },
              ])}
            />
            <ToastContainer />
          </Provider>
        </AppProvider>
      </I18nextProvider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
