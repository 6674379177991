import { Form, Col, Row, Accordion, Button } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import { ChangeEvent, useEffect, useState } from "react";
import IExercise from "../../../interfaces/workouts/IExercice";
import IntervalForm from "./IntervalForm";
import { MdAdd } from "react-icons/md";
import IIntervals from "../../../interfaces/workouts/IIntervals";

interface Props {
  hide: () => void;
  exerciseProp?: IExercise;
  updateExercice: (exercice: IExercise) => void;
  action: boolean;
}

export default function ExerciseForm({
  hide,
  exerciseProp,
  updateExercice,
  action,
}: Props) {
  const { translate } = UseAppContext();
  const [exercise, setExercise] = useState(exerciseProp || ({} as IExercise));

  useEffect(() => {
    if (exerciseProp) {
      setExercise(exerciseProp);
    }
  }, [exerciseProp]);

  useEffect(() => {
    if (action) {
      updateOrCreateExercise();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setExercise((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function removeInterval(position: number) {
    const newExercice = { ...exercise } as IExercise;
    const newIntervals =
      exercise.intervals?.filter((interval, index) => index !== position) || [];

    newExercice.intervals = newIntervals;
    updateOrCreateExercise(newExercice, true);
  }

  function updateInterval(newInterval: IIntervals, position: number) {
    const newExercice = { ...exercise } as IExercise;

    const newIntervals =
      exercise.intervals?.map((interval, index) =>
        index === position ? newInterval : interval
      ) || [];

    if (position > -1) {
      newExercice.intervals = newIntervals;
      updateOrCreateExercise(newExercice, true);
      return;
    }

    newInterval.position = newIntervals?.length - 1;
    newIntervals.push(newInterval);
    newExercice.intervals = newIntervals;
    updateOrCreateExercise(newExercice, true);
  }

  async function updateOrCreateExercise(
    newExercice?: IExercise,
    keepOpen?: boolean
  ) {
    updateExercice(newExercice || exercise);
    if (!keepOpen) {
      hide();
    }
  }

  function createNewInterval() {
    const newInterval = { name: translate("newInterval") } as IIntervals;
    updateInterval(newInterval, -1);
  }

  return (
    <div className="d-flex flex-column">
      <Form className="mb-2 text-start mx-2">
        <Form.Group controlId="name">
          <Form.Label className="mb-0 mt-2"> {translate("name")}</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={exercise.name || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Row>
          <Col>
            <Form.Group controlId="series">
              <Form.Label className="mb-0 mt-2">
                {" "}
                {translate("series")}
              </Form.Label>
              <Form.Control
                type="number"
                name="series"
                value={exercise.series || ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="repetitions">
              <Form.Label className="mb-0 mt-2">
                {" "}
                {translate("repetitions")}
              </Form.Label>
              <Form.Control
                type="number"
                name="repetitions"
                value={exercise.repetitions || ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="weight">
              <Form.Label className="mb-0 mt-2">
                {" "}
                {translate("weight")}
              </Form.Label>
              <Form.Control
                type="text"
                name="weight"
                value={exercise.weight || ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="intensity">
          <Form.Label className="mb-0 mt-2">
            {" "}
            {translate("intensity")}
          </Form.Label>
          <Form.Control
            type="text"
            name="intensity"
            value={exercise.intensity || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Row>
          <Col>
            <Form.Group controlId="distance">
              <Form.Label className="mb-0 mt-2">
                {" "}
                {translate("distance")}
              </Form.Label>
              <Form.Control
                type="text"
                name="distance"
                value={exercise.distance || ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="pace">
              <Form.Label className="mb-0 mt-2">
                {" "}
                {translate("pace")}
              </Form.Label>
              <Form.Control
                type="text"
                name="pace"
                value={exercise.pace || ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="time">
          <Form.Label className="mb-0 mt-2"> {translate("time")}</Form.Label>
          <Form.Control
            type="text"
            name="time"
            value={exercise.time || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="rest">
          <Form.Label className="mb-0 mt-2"> {translate("rest")}</Form.Label>
          <Form.Control
            type="text"
            name="rest"
            value={exercise.rest || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="info">
          <Form.Label className="mb-0 mt-2"> {translate("info")}</Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            name="info"
            value={exercise.info || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="haveIntervals">
          <Form.Check
            type="checkbox"
            label={translate("haveIntervals")}
            name="haveIntervals"
            className="mt-3"
            checked={exercise.haveIntervals || false}
            onChange={(e) =>
              setExercise((prev) => ({
                ...prev,
                haveIntervals: e.target.checked,
              }))
            }
          />
        </Form.Group>

        <Accordion defaultActiveKey="-1">
          {exercise.haveIntervals && (
            <>
              <hr />
              <div className="d-flex py-1">
                <h5 className="my-auto">{translate("intervals")}</h5>{" "}
                <Button
                  size="sm"
                  style={{ maxHeight: "35px" }}
                  className="me-0 ms-auto my-auto"
                  onClick={createNewInterval}
                >
                  <MdAdd />
                </Button>
              </div>
              {(exercise.intervals || []).map((interval, index) => (
                <Accordion.Item
                  eventKey={index?.toString() || ""}
                  key={`interval-${index}-${interval.name}`}
                >
                  <Accordion.Header className="d-flex">
                    {index + 1} - {interval.name}
                  </Accordion.Header>
                  <Accordion.Body className="py-2">
                    <IntervalForm
                      intervalProp={interval}
                      updateInterval={(interval: IIntervals) => {
                        updateInterval(interval, index);
                      }}
                      removeInterval={() => {
                        removeInterval(index);
                      }}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </>
          )}
        </Accordion>
      </Form>
    </div>
  );
}
