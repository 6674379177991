import { Container } from "react-bootstrap";
import { UseAppContext } from "../../context/appContext";
import ProfileCard from "../user/ProfileCard";
import SubcriptionsList from "../user/subcriptions/SubscriptionsList";
import ClientsList from "../clients/ClientsLists";

export default function UserDashboard() {
  const { user } = UseAppContext();

  const isProfessor = user.type === "professor";

  return (
    <Container className="mt-3">
      <div>
        <ProfileCard />
      </div>

      {isProfessor && (
        <div className="mt-3 bg-white rounded p-2 border">
          <ClientsList />
        </div>
      )}
      <div className="mt-2">{<SubcriptionsList />}</div>
    </Container>
  );
}
