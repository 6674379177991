import { useState, ChangeEvent } from "react";
import { Button, Form } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import IIntervals from "../../../interfaces/workouts/IIntervals";

interface Props {
  intervalProp?: IIntervals;
  updateInterval: (interval: IIntervals) => void;
  removeInterval: () => void;
}

export default function IntervalForm({
  intervalProp,
  updateInterval,
  removeInterval,
}: Props) {
  const { translate } = UseAppContext();
  const editing = Boolean(intervalProp?.name);
  const [interval, setInterval] = useState<IIntervals>(
    intervalProp || ({} as IIntervals)
  );

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setInterval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  async function updateIntervalOrCreateInterval() {
    updateInterval(interval);
  }

  return (
    <div className="d-flex flex-column mb-2">
      <div className="mb-2 text-start mx-0">
        <Form.Group controlId="name">
          <Form.Label className="mb-1">{translate("name")}</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={interval.name || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="info">
          <Form.Label className="mb-1">{translate("info")}</Form.Label>
          <Form.Control
            type="text"
            name="info"
            value={interval.info || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="time">
          <Form.Label className="mb-1">{translate("time")}</Form.Label>
          <Form.Control
            type="text"
            name="time"
            value={interval.time || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="distance">
          <Form.Label className="mb-1">{translate("distance")}</Form.Label>
          <Form.Control
            type="text"
            name="distance"
            value={interval.distance || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="pace">
          <Form.Label className="mb-1">{translate("pace")}</Form.Label>
          <Form.Control
            type="text"
            name="pace"
            value={interval.pace || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="intensity">
          <Form.Label className="mb-1">{translate("intensity")}</Form.Label>
          <Form.Control
            type="text"
            name="intensity"
            value={interval.intensity || ""}
            onChange={handleChange}
          />
        </Form.Group>
      </div>

      <div className="d-flex mt-1 mb-1">
        <Button
          variant="primary"
          size="sm"
          className="me-1 w-100"
          onClick={() => updateIntervalOrCreateInterval()}
        >
          {translate(editing ? "editInterval" : "createInterval")}
        </Button>

        <Button
          variant="outline-primary"
          size="sm"
          className="ms-1 w-100"
          onClick={() => removeInterval()}
        >
          {translate("removeInterval")}
        </Button>
      </div>
    </div>
  );
}
