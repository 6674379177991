import { Button, Card, Modal, Spinner } from "react-bootstrap";
import { UseAppContext } from "../../context/appContext";
import ProfileForm from "./ProfileForm";
import { useEffect, useState } from "react";

interface props {
  show: boolean;
  hide: () => void;
}

export default function ProfileModal({ show, hide }: props) {
  const { translate } = UseAppContext();
  const [action, setAction] = useState(false);

  useEffect(() => {
    if (action) {
      const timer = setTimeout(() => {
        setAction(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [action]);

  return (
    <Modal
      show={show}
      onHide={() => {
        hide();
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-none"
    >
      <Modal.Header className="py-2 text-center">
        <h4 className="text-center my-0 mx-auto">{translate("editProfile")}</h4>
      </Modal.Header>

      <Modal.Body className="p-0">
        <Card
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "calc(85vh - 80px)",
            border: "none",
          }}
          className="px-2"
        >
          <ProfileForm action={action} />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={action}
          onClick={() => {
            setAction(true);
          }}
        >
          {action && <Spinner size="sm" className="me-2" />}
          {translate("editProfile")}
        </Button>
        <Button onClick={hide} variant="secondary" disabled={action}>
          {translate("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
