import IIntervals from "../../../interfaces/workouts/IIntervals";

interface props {
  interval: IIntervals;
}

export default function WorkoutExcerciceInterval({ interval }: props) {
  function intervalInfoText() {
    let response = "";
    if (interval?.distance) {
      response = `${response} ${interval.distance}`;
    }

    if (interval?.time) {
      response = `${response}, ${interval.time}`;
    }

    if (interval?.pace) {
      response = `${response}, ${interval.pace}`;
    }

    if (interval?.intensity) {
      response = `${response}, ${interval.intensity}`;
    }

    if (interval?.info) {
      response = `${response}, ${interval.info}`;
    }

    return response;
  }

  return (
    <div className="border-start rounded border-bottom mt-1 mb-2 p-1 ps-2 ms-1">
      <small>
        {interval.position + 1} - <b>{interval.name}</b>
        {intervalInfoText()}
      </small>
    </div>
  );
}
