import { Button, Image } from "react-bootstrap";
import ISubscription from "../../../interfaces/subscriptions/ISubscription";
import { UseAppContext } from "../../../context/appContext";
import { useNavigate } from "react-router-dom";
import { FaBan, FaCaretRight } from "react-icons/fa";

interface props {
  subcription: ISubscription;
}

export default function SubscriptionCard({ subcription }: props) {
  const { translate } = UseAppContext();
  const navitate = useNavigate();

  function goToSubcriptionDashboard() {
    if (!subcription.canceled) {
      navitate(`/subscriptions/${subcription.professor}`);
    }
  }

  return (
    <div
      className="rounded p-2 text-start mt-1 d-flex pointer border border-grey size-hover bg-white"
      onClick={goToSubcriptionDashboard}
    >
      <div>
        <Image src={subcription.professorPic} width={50} className="rounded" />
      </div>

      <div className="d-flex w-100 p-2 flex-column">
        <p className="mb-0 mt-1" style={{ lineHeight: "1" }}>
          {translate("professor")}
        </p>
        {subcription.canceled && (
          <p className="mb-0">
            <strong>{translate("subcriptionBlockedByProfessor")}</strong>
          </p>
        )}
        <p className="mb-0">
          <strong>{subcription.professorName}</strong>
        </p>
      </div>
      <div className="d-flex">
        <Button
          className="my-auto"
          size="sm"
          disabled={subcription.canceled}
          onClick={goToSubcriptionDashboard}
        >
          <span className="m-auto">
            {subcription.canceled ? (
              <FaBan className="me-2" />
            ) : (
              <FaCaretRight />
            )}
          </span>
        </Button>
      </div>
    </div>
  );
}
