import ClientWorkouts from "../components/workouts/client/ClientWorkouts";
import AppContainer from "../containers/AppContainer";

export default function Client() {
  return (
    <AppContainer>
      <div className="p-2">
        <ClientWorkouts />
      </div>
    </AppContainer>
  );
}
