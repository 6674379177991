import { Button, Form } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import IClient from "../../../interfaces/IClient";
import { useSelector } from "react-redux";
import IUser from "../../../interfaces/users/IUser";
import { FaCopy, FaShare } from "react-icons/fa";
import { toast } from "react-toastify";

interface props {
  currentClient?: IClient;
  hide: () => void;
}

export default function LinkForm({ currentClient }: props) {
  const { translate, isMobile } = UseAppContext();
  const user = useSelector((state: any) => state.user.user) as IUser;

  const link =
    window.location.protocol +
    "//" +
    window.location.host +
    "/subscribe?professor=" +
    user.link +
    "&pin=" +
    currentClient?.pin;

  // Función para copiar el link al portapapeles
  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success(translate("copied"), {
          position: "top-center",
          autoClose: 2000,
        });
      })
      .catch((err) => {
        console.error("Error al copiar el link:", err);
      });
  };

  // Función para compartir el link
  const handleShareLink = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Comparte este enlace",
          text: "¡Echa un vistazo a este link!",
          url: link,
        })
        .then(() => {
          toast.success(translate("copied"), {
            position: "top-center",
            autoClose: 2000,
          });
        })
        .catch((err) => console.error("Error al compartir el link:", err));
    } else {
      toast.error(translate("onlyOnMobile"), {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="d-flex flex-column">
      <h4 className="text-center">{translate("linkClient")}</h4>

      <p>{translate("howToLinkClient")}</p>

      <Form.Label className="mt-2 mb-1">{translate("link")}:</Form.Label>

      <p className="sublined">{link}</p>

      <div className={isMobile ? "d-flex" : ""}>
        <Button
          size="sm"
          variant="primary"
          className={"mx-auto px-3 " + (!isMobile ? "me-2" : "")}
          onClick={handleCopyLink}
        >
          <FaCopy className="me-2" /> {translate("copy")}
        </Button>

        <Button
          size="sm"
          variant="outline-primary"
          className="mx-auto px-3 "
          onClick={handleShareLink}
        >
          <FaShare className="me-2" /> {translate("share")}
        </Button>
      </div>

      {false && (
        <>
          <p className="mt-3">{translate("howToLinkClient2")}</p>

          <Form.Group controlId="userLink">
            <Form.Label className="mb-1">
              {translate("professorLink")}
            </Form.Label>
            <Form.Control
              type="text"
              name="professor"
              value={user.link}
              readOnly
            />
          </Form.Group>

          <Form.Group controlId="clientPin">
            <Form.Label className="mt-2 mb-1">
              {translate("clientPin")}
            </Form.Label>
            <Form.Control
              type="text"
              name="pin"
              value={currentClient?.pin}
              readOnly
            />
          </Form.Group>
        </>
      )}
    </div>
  );
}
