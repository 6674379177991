import { Button, Modal } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";

interface props {
  show: boolean;
  hide: () => void;
  confirm: () => void;
}

export default function DeleteWorkoutModal({ show, hide, confirm }: props) {
  const { translate } = UseAppContext();

  return (
    <Modal
      show={show}
      onHide={() => {
        hide();
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-none"
    >
      <Modal.Header>{translate("deleteWorkoutInfoTitle")}</Modal.Header>
      <Modal.Body>
        <div
          className="text-center"
          dangerouslySetInnerHTML={{ __html: translate("deleteWorkoutInfo") }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={confirm} variant="danger">
          {translate("confirmDelete")}
        </Button>
        <Button onClick={hide} variant="secondary">
          {translate("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
