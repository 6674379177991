import { useState } from "react";
import { Button } from "react-bootstrap";
import { UseAppContext } from "../../context/appContext";
import ClientModal from "./ClientModal";

export default function CreateClientButton() {
  const { translate } = UseAppContext();
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        size="sm"
        onClick={() => {
          setShow(true);
        }}
      >
        {translate("createClient")}
      </Button>

      <ClientModal
        show={show}
        hide={() => {
          setShow(false);
        }}
      />
    </>
  );
}
