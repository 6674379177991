import { Button, Card, Modal, Spinner } from "react-bootstrap";
import ClientForm from "./ClientForm";
import IClient from "../../interfaces/IClient";
import { UseAppContext } from "../../context/appContext";
import { useEffect, useState } from "react";

interface props {
  show: boolean;
  hide: () => void;
  currentClient?: IClient;
  updateClient?: (client: IClient) => void;
}

export default function ClientModal({
  show,
  hide,
  currentClient,
  updateClient,
}: props) {
  const { translate } = UseAppContext();
  const [action, setAction] = useState(false);
  const editing = Boolean(currentClient);

  useEffect(() => {
    if (action) {
      const timer = setTimeout(() => {
        setAction(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [action]);

  useEffect(() => {
    setAction(false);
  }, [currentClient]);

  return (
    <Modal
      show={show}
      onHide={() => {
        hide();
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-none"
    >
      <Modal.Header className="py-2 text-center">
        <h4 className="text-center my-0 mx-auto">
          {translate(editing ? "editClient" : "createClient")}
        </h4>
      </Modal.Header>

      <Modal.Body className="p-0">
        <Card
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "calc(85vh - 80px)",
            border: "none",
          }}
          className="px-2"
        >
          <ClientForm
            currentClient={currentClient}
            hide={hide}
            action={action}
            updateClient={(val: IClient) => {
              if (updateClient) {
                updateClient(val);
              }
            }}
          />
        </Card>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="primary"
          disabled={action}
          onClick={() => {
            setAction(true);
          }}
        >
          {action && <Spinner size="sm" className="me-2" />}
          {translate(editing ? "editClient" : "createClient")}
        </Button>
        <Button onClick={hide} variant="secondary" disabled={action}>
          {translate("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
