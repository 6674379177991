import { Button, Card, Modal } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import ExerciseForm from "./ExerciseForm";
import IExercise from "../../../interfaces/workouts/IExercice";
import { useEffect, useState } from "react";

interface props {
  show: boolean;
  hide: () => void;
  exerciseProp?: IExercise;
  updateExercice: (exercice: IExercise) => void;
}

export default function ExerciseModal({
  show,
  hide,
  exerciseProp,
  updateExercice,
}: props) {
  const { translate } = UseAppContext();
  const editing = Boolean(exerciseProp?.name);
  const [action, setAction] = useState(false);

  useEffect(() => {
    setAction(false);
  }, [exerciseProp]);

  return (
    <Modal
      show={show}
      onHide={() => {
        hide();
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-none"
    >
      <Modal.Header className="py-2 text-center">
        <h4 className="text-center my-0 mx-auto">{translate("exercise")}</h4>
      </Modal.Header>

      <Modal.Body className="p-0">
        <Card
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "calc(85vh - 80px)",
            border: "none",
          }}
          className="px-2"
        >
          <ExerciseForm
            hide={hide}
            exerciseProp={exerciseProp}
            updateExercice={updateExercice}
            action={action}
          />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            setAction(true);
          }}
        >
          {translate(editing ? "edit" : "create")}
        </Button>

        <Button onClick={hide} variant="secondary">
          {translate("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
