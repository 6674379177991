import { Form, Image as ReactImage } from "react-bootstrap";
import { axiosContext, UseAppContext } from "../../context/appContext";
import { useEffect, useRef, useState } from "react";
import { FormEvent } from "../../types/FormEvent";
import { toast } from "react-toastify";
import IResponse from "../../interfaces/IResponse";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/actionTypes";
import IProfile from "../../interfaces/users/IProfile";
import { fetchPersonalProfile } from "../../store/userReducer";

interface Props {
  action: boolean;
}

export default function ProfileForm({ action }: Props) {
  const { translate, user } = UseAppContext();
  const storedProfile = useSelector(
    (state: any) => state.user.personalProfile
  ) as IProfile;
  const [profile, setProfile] = useState(storedProfile || ({} as IProfile));
  const dispatch: AppDispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const isProfessor = user.type === "professor";
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(storedProfile.pic);

  useEffect(() => {
    if (action) {
      updateProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const handleImageClick = () => {
    const currentImage = fileInputRef.current as any;
    if (currentImage) {
      currentImage.click();
    }
  };

  useEffect(() => {
    if (storedProfile && storedProfile.pic !== profile.pic) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        pic: imageUrl,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedProfile]);

  const handleProfileChange = (e: FormEvent) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  async function updateProfile() {
    setLoading(true);
    try {
      const newProfile = { ...profile };

      newProfile.type = isProfessor ? "professional" : "personal";
      const response = await axiosContext.post(
        "/app/user/updateProfile",
        profile
      );

      if (!response?.data) {
        toast.error(translate("error"), {
          position: "top-center",
          autoClose: 8000,
        });
        return;
      }

      const responseData = response?.data as IResponse;

      if (responseData.success) {
        toast.success(translate("success"), {
          position: "top-center",
          autoClose: 2000,
          onClose: () => {
            dispatch(fetchPersonalProfile());
          },
        });
      }
      setLoading(false);
    } catch (e: any) {
      toast.error(e.toString(), {
        position: "top-center",
        autoClose: 8000,
      });
      setLoading(false);
    }
  }

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (file) {
      upadateFileImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  async function upadateFileImage() {
    if (!file) {
      return false;
    }
    const formData = new FormData();
    formData.append("pic", file);

    const response = await axiosContext.post(
      "/app/user/updateProfilePic",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (!response?.data) {
      toast.error(translate("error"), {
        position: "top-center",
        autoClose: 8000,
      });
      return;
    }

    const responseData = response?.data as IResponse;

    if (responseData.success) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        pic: responseData?.data?.url,
      }));
    }
  }

  return (
    <div>
      <Form className="mb-4 mx-2 mt-3">
        <div className="d-flex">
          <ReactImage
            src={imageUrl}
            className="mx-auto text-center"
            roundedCircle
            width={96}
            onClick={() => {
              handleImageClick();
            }}
          />
        </div>

        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          onChangeCapture={handleFileChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />

        <Form.Group controlId="name">
          <Form.Label>{translate("name")}</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={profile.name || ""}
            onChange={handleProfileChange}
            required
            isInvalid={!Boolean(profile.name)}
          />
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>{translate("email")}</Form.Label>
          <Form.Control
            type="text"
            name="email"
            value={profile.email || ""}
            onChange={handleProfileChange}
          />
        </Form.Group>

        <Form.Group controlId="gender">
          <Form.Label>{translate("gender")}</Form.Label>
          <Form.Control
            type="text"
            name="gender"
            placeholder={translate("genderInfo")}
            value={profile.gender || ""}
            onChange={handleProfileChange}
          />
        </Form.Group>

        <Form.Group controlId="age">
          <Form.Label>{translate("ageYears")}</Form.Label>
          <Form.Control
            type="number"
            name="age"
            value={profile.age || ""}
            onChange={handleProfileChange}
          />
        </Form.Group>

        <Form.Group controlId="weight">
          <Form.Label>{translate("weightKg")}</Form.Label>
          <Form.Control
            type="number"
            name="weight"
            value={profile.weight || ""}
            onChange={handleProfileChange}
          />
        </Form.Group>

        <Form.Group controlId="fitnessLevel">
          <Form.Label>{translate("fitnessInfo")}</Form.Label>
          <Form.Control
            type="text"
            name="fitnessLevel"
            placeholder={translate("fitnessExample")}
            value={profile.fitnessLevel || ""}
            onChange={handleProfileChange}
          />
        </Form.Group>

        <Form.Group controlId="goals">
          <Form.Label>{translate("goals")}</Form.Label>
          <Form.Control
            type="text"
            name="goals"
            placeholder={translate("goalsExample")}
            value={profile.goals || ""}
            onChange={handleProfileChange}
          />
        </Form.Group>

        <Form.Group controlId="limitations">
          <Form.Label>{translate("limitations")}</Form.Label>
          <Form.Control
            type="text"
            name="limitations"
            as="textarea"
            placeholder={translate("limitationsInfo")}
            value={profile.limitations || ""}
            onChange={handleProfileChange}
          />
        </Form.Group>

        <Form.Group controlId="info">
          <Form.Label>{translate("infos")}</Form.Label>
          <Form.Control
            type="text"
            name="info"
            as="textarea"
            placeholder={translate("extraInfo")}
            value={profile.info || ""}
            onChange={handleProfileChange}
          />
        </Form.Group>
      </Form>
    </div>
  );
}
