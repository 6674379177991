import { LoginForm } from "../components/user/LoginForm";
import AppContainer from "../containers/AppContainer";
import { UseAppContext } from "../context/appContext";
import UserDashboard from "../components/dashboards/UserDashboard";

export default function Home() {
  const { isLogged } = UseAppContext();
  return (
    <AppContainer>
      {isLogged ? (
        <div>
          <UserDashboard />{" "}
        </div>
      ) : (
        <LoginForm />
      )}
    </AppContainer>
  );
}
