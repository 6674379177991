import { useDispatch, useSelector } from "react-redux";
import { UseAppContext } from "../../../context/appContext";
import ISubscription from "../../../interfaces/subscriptions/ISubscription";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchSubscriptionWorkouts } from "../../../store/workoutReducer";
import IWorkoutResponse from "../../../interfaces/workouts/IWorkoutResponse";
import WorkoutsList from "../../workouts/WorkoutsList";
import { FaBan } from "react-icons/fa";

export default function SubcriptionDashboard() {
  const { translate } = UseAppContext();
  const { professor } = useParams();
  const subcriptions = useSelector(
    (state: any) => state.user.subcriptions
  ) as Array<ISubscription>;

  const currentSubscription = subcriptions?.find(
    (sub) => sub.professor === professor
  );

  const workouts = useSelector(
    (state: any) => state.workout.subcriptionWorkouts
  ) as Array<IWorkoutResponse>;

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentSubscription) {
      getSubscriptionWorkouts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSubscription]);

  async function getSubscriptionWorkouts() {
    dispatch(
      fetchSubscriptionWorkouts({
        user: currentSubscription?.user || "",
        professor: professor || "",
      }) as any
    );
  }

  return (
    <div>
      <h4>
        {translate("professor")} {currentSubscription?.professorName}
      </h4>

      {currentSubscription?.canceled ? (
        <div className="mt-4 d-flex flex-column">
          <FaBan className="mx-auto mb-3" color="danger" size={40} />
          <h5 className="mx-auto">
            {translate("subcriptionBlockedByProfessor")}
          </h5>
        </div>
      ) : (
        <WorkoutsList workouts={workouts} />
      )}
    </div>
  );
}
