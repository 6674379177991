import { useEffect, useState } from "react";
import IWorkoutResponse from "./../../interfaces/workouts/IWorkoutResponse";
import { UseAppContext } from "./../../context/appContext";
import IWorkoutRequest from "./../../interfaces/workouts/IWorkoutRequest";
import CreatingWorkoutModal from "./modals/CreatingWorkoutModal";
import ClientWorkoutModal from "./client/ClientWorkoutModal";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import WorkoutSimpleCard from "./card/WorkoutSimpleCard";

interface props {
  workouts: Array<IWorkoutResponse>;
}

export default function WorkoutsList({ workouts }: props) {
  const [currentWorkout, setCurrentWorkout] = useState({} as IWorkoutResponse);
  const [haveWorkouts, setHaveWorkouts] = useState(Boolean(workouts.length));
  const { translate, isMobile } = UseAppContext();
  const [show, setShow] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [workoutToSend, setWoroutToSend] = useState({} as IWorkoutRequest);
  const loading = useSelector(
    (state: any) => state.workout.loadingWorkout
  ) as boolean;

  useEffect(() => {
    setHaveWorkouts(Boolean(workouts.length));
  }, [workouts]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function createWorkoutAction() {
    setCurrentWorkout({} as IWorkoutResponse);
    setShow(true);
  }

  return (
    <div className={"border rounded p-2 " + (isMobile ? "mt-3" : "")}>
      {loading && <Spinner size="sm" className="ma-auto" />}

      {!haveWorkouts && !loading && <p>{translate("notWorkoutsYet")}</p>}

      {workouts?.map((workout, index) => {
        return (
          <WorkoutSimpleCard
            key={`${workout.id}-${index}`}
            currentWorkout={workout}
          />
        );
      })}

      <ClientWorkoutModal
        currentWorkout={currentWorkout}
        show={show}
        hide={() => {
          setShow(false);
        }}
        showCreating={(workoutToSend: IWorkoutRequest) => {
          setWoroutToSend(workoutToSend);
          setShowCreate(true);
          setShow(false);
        }}
      />

      <CreatingWorkoutModal
        show={showCreate}
        hide={() => {
          setShowCreate(false);
        }}
        workoutToSend={workoutToSend}
      />
    </div>
  );
}
