import { Button, Form, Spinner } from "react-bootstrap";
import { UseAppContext } from "../../context/appContext";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../config/firebaseConfig";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import "./loginForm.scss";
import Logo from "../../assets/brand/logo.png";

export function LoginForm() {
  const { translate } = UseAppContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [accepted, setAccepted] = useState(true);
  const [searchParams] = useSearchParams();
  const professor = searchParams.get("professor");
  const pin = searchParams.get("pin");
  const [showLogin, setShowLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [pass2, setPass2] = useState("");
  const [newUser, setNewUser] = useState(false);

  function signIn() {
    setLoading(true);
    setError("");
    localStorage.setItem("reload", "true");
    const provider = new GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");

    signInWithPopup(auth, provider)
      .catch((e) => {
        const myError = e.toString();
        setError(myError);
        if (myError.includes("auth/popup-closed-by-user")) {
          setError(translate("loginPopupClosedByUser"));
        }
        setLoading(false);
        localStorage.setItem("reload", "");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function emailSignin() {
    setLoading(true);
    setError("");
    localStorage.setItem("reload", "true");
    signInWithEmailAndPassword(auth, email, pass)
      .catch((e) => {
        const myError = e.toString();
        setError(myError);
        if (myError.includes("auth/invalid-credential")) {
          setError(translate("emailOrPassError"));
        }
        setLoading(false);
        localStorage.setItem("reload", "");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function emailSignUp() {
    setError("");

    if (pass !== pass2) {
      setError(translate("differentPasswords"));
      return;
    }

    setLoading(true);

    localStorage.setItem("reload", "true");

    createUserWithEmailAndPassword(auth, email, pass)
      .catch((e) => {
        const myError = e.toString();
        setError(myError);
        if (myError.includes("auth/invalid-credential")) {
          setError(translate("emailOrPassError"));
        }
        setLoading(false);
        localStorage.setItem("reload", "");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div
      id="login-form"
      className="d-flex flex-column text-center"
      style={{ minHeight: "90vh" }}
    >
      <div className="m-auto px-2">
        <img src={Logo} alt="Gymster logo" className="mx-auto" width={100} />
        <h1 className="mt-1 titles">{translate("appName")}</h1>
        <h6>{translate("appInfo")}</h6>

        {(professor || pin) && (
          <p className="mb-0">
            <small>{translate("subscribeDescription")}</small>
          </p>
        )}

        {showLogin ? (
          <div className="my-2">
            <h6 className="mb-1">{translate("login")}</h6>

            <Form.Group>
              <Form.Control
                placeholder={translate("email")}
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group className="mt-1">
              <Form.Control
                placeholder={translate("pass")}
                type="password"
                value={pass}
                onChange={(e) => {
                  setPass(e.target.value);
                }}
              />
            </Form.Group>

            {newUser && (
              <Form.Group className="mt-1">
                <Form.Control
                  placeholder={translate("passRepeat")}
                  type="password"
                  value={pass2}
                  onChange={(e) => {
                    setPass2(e.target.value);
                  }}
                />
              </Form.Group>
            )}

            <div className="d-flex">
              <Button
                variant="primary"
                className={"d-flex mx-auto mb-0 mt-2 w-100 me-2"}
                disabled={!accepted || loading || !email.length || !pass.length}
                onClick={() => {
                  if (
                    !newUser &&
                    email &&
                    email.length &&
                    pass &&
                    pass.length
                  ) {
                    emailSignin();
                  }

                  if (newUser && email && email.length && pass && pass.length) {
                    emailSignUp();
                  }
                }}
              >
                <span className="mx-auto d-flex">
                  {loading && <Spinner size="sm" className="me-2 my-auto" />}
                  <div className="my-auto">
                    {newUser ? translate("signUp") : translate("enter")}
                  </div>
                </span>
              </Button>
              <Button
                variant="outline-primary"
                className={"d-flex mx-auto mb-0 mt-2"}
                disabled={loading}
                onClick={() => {
                  setError("");
                  setShowLogin(false);
                }}
              >
                <span className="mx-auto d-flex">
                  {loading && <Spinner size="sm" className="me-2 my-auto" />}
                  <div className="my-auto">{translate("back")}</div>
                </span>
              </Button>
            </div>
            <div className="d-flex">
              <Button
                variant="primary"
                className={"d-flex mx-auto mb-0 mt-2 w-100"}
                disabled={!accepted || loading || !email.length || !pass.length}
                onClick={() => {
                  setNewUser(!newUser);
                }}
              >
                <span className="mx-auto d-flex">
                  {loading && <Spinner size="sm" className="me-2 my-auto" />}
                  <div className="my-auto">
                    {newUser ? translate("login") : translate("signUp")}
                  </div>
                </span>
              </Button>
            </div>
          </div>
        ) : (
          <>
            <Button
              variant="primary"
              className={
                "d-flex mx-auto mb-0 " + (professor || pin ? "mt-2" : "mt-4")
              }
              style={{ minWidth: "200px" }}
              disabled={!accepted || loading}
              onClick={() => {
                signIn();
              }}
            >
              <span className="mx-auto d-flex">
                {loading && <Spinner size="sm" className="me-2 my-auto" />}
                <div className="my-auto">{translate("googleLogin")}</div>
              </span>
            </Button>
            <Button
              variant="primary"
              className={"d-flex mx-auto mb-0 mt-2"}
              style={{ minWidth: "200px" }}
              disabled={!accepted || loading}
              onClick={() => {
                setShowLogin(true);
              }}
            >
              <span className="mx-auto d-flex">
                {loading && <Spinner size="sm" className="me-2 my-auto" />}
                <div className="my-auto">{translate("emailLogin")}</div>
              </span>
            </Button>
          </>
        )}

        {error && (
          <div className="text-danger mt-1 mx-2" style={{ maxWidth: "275px" }}>
            <small>{error}</small>
          </div>
        )}

        <Form.Group className="mt-3 custom-checkbox-container pointer">
          <Form.Check
            type="checkbox"
            className="custom-checkbox text-12 pointer my-auto"
            checked={accepted}
            onChange={(e) => setAccepted(e.target.checked)}
          />

          <Link
            to="/terms"
            className="my-auto me-2 text-primary"
            style={{ maxHeight: "18px", padding: "0px", lineHeight: "0" }}
          >
            <small style={{ fontSize: "0.7em" }}>
              {translate("acceptTerms")}
            </small>
          </Link>
        </Form.Group>
      </div>
    </div>
  );
}
