import { Button } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { UseAppContext } from "../../../context/appContext";
import { useState } from "react";
import IExercise from "../../../interfaces/workouts/IExercice";
import ExerciseModal from "./ExerciseModal";

interface props {
  className?: string;
  exercise?: IExercise;
  updateExercice: (exercice: IExercise) => void;
  buttonVariant?: string;
  buttonSize?: string;
}
export default function EditExerciseButton({
  className,
  exercise,
  updateExercice,
  buttonVariant,
  buttonSize,
}: props) {
  const { isMobile, user } = UseAppContext();
  const isProfessor = user.type === "professor";
  const [show, setShow] = useState(false);

  if (!isProfessor || exercise?.done) {
    return null;
  }

  const size = buttonSize ? buttonSize : isMobile ? "26px" : "32px";
  const variant = buttonVariant ? buttonVariant : isMobile ? "" : "white";

  function openModal() {
    setShow(true);
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Button
        size="sm"
        as="div"
        variant={variant}
        className={
          "d-flex " + (isMobile ? "p-0 " : "") + (className ? className : "")
        }
        style={{ width: size, height: size }}
        onClick={(e) => {
          e.stopPropagation();
          openModal();
        }}
      >
        <FaEdit className="m-auto" />
      </Button>
      <ExerciseModal
        show={show && !exercise?.done}
        exerciseProp={exercise}
        hide={() => {
          setShow(false);
        }}
        updateExercice={updateExercice}
      />
    </div>
  );
}
