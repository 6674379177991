import { useEffect, useState } from "react";
import { UseAppContext } from "../../context/appContext";
import IClient from "../../interfaces/IClient";
import { useDispatch } from "react-redux";
import { setStoreClient } from "../../store/clientReducer";
import { AppDispatch } from "../../store/actionTypes";
import ClientModal from "./ClientModal";
import { Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LinkModal from "./link/LinkModal";
import Logo from "../../assets/brand/logo-fallback.png";
import { FaCaretRight } from "react-icons/fa";
import { toast } from "react-toastify";
import "./clientCard.scss";

interface Props {
  client: IClient;
}

export default function ClientSimpleCard({ client }: Props) {
  const { translate, isMobile } = UseAppContext();
  const dispatch: AppDispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const navigate = useNavigate();
  const [selectedClient, setSelectedClient] = useState(client);
  const [confirmBlock, setConfirmBlock] = useState(false);

  useEffect(() => {
    if (confirmBlock) {
      const timer = setTimeout(() => {
        setConfirmBlock(false);
      }, 5000);

      if (isMobile) {
        toast.info(translate("confirmClick"), {
          position: "top-center",
          autoClose: 4000,
          toastId: "infoConfirm",
        });
      }

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmBlock]);

  function selectClient() {
    setSelectedClient(client);
    dispatch(setStoreClient(client));
    navigate(`/client/${selectedClient.id}`);
  }

  return (
    <div
      className=""
      onClick={() => {
        selectClient();
      }}
    >
      <div
        className={
          "rounded p-2 text-start mt-1 d-flex pointer border border-grey size-hover bg-white "
        }
        key={selectedClient.id}
      >
        <div className="d-flex">
          <Image
            src={selectedClient.pic || Logo}
            width={isMobile ? 50 : 70}
            height={isMobile ? undefined : 70}
            className={
              "rounded " +
              (isMobile ? "my-auto " : "") +
              (selectedClient.pic ? "" : "border bg-secondary")
            }
          />
        </div>

        <div className="px-2 w-100">
          <h5 className="mb-0">{selectedClient.name}</h5>
          <p className="mb-0 small">
            {translate(selectedClient.gender || "male")}, {selectedClient.age}{" "}
            {translate("years")}, {selectedClient.weight}
            {translate("kg")}
          </p>
          {!isMobile && selectedClient.goals && (
            <p className="mb-0">{selectedClient.goals}</p>
          )}
        </div>
        <div className="d-flex">
          <Button className="my-auto">
            {" "}
            <FaCaretRight />{" "}
          </Button>
        </div>
      </div>

      <ClientModal
        show={show}
        hide={() => {
          setShow(false);
        }}
        currentClient={selectedClient}
      />

      <LinkModal
        show={showLink}
        hide={() => {
          setShowLink(false);
        }}
        currentClient={selectedClient}
      />
    </div>
  );
}
