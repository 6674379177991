import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientState } from "./actionTypes";
import IClient from "../interfaces/IClient";
import { axiosContext } from "../context/appContext";

const initialState: ClientState = {
  client: {} as IClient,
  clients: [] as Array<IClient>,
};

export const fetchClients = createAsyncThunk(
  "client/fetchClients",
  async () => {
    try {
      const response = await axiosContext.get("/app/clients/getClients");
      if (response.data?.success) {
        const newClients = response.data?.data || ([] as Array<IClient>);
        return newClients;
      }
      return [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }
);

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setStoreClient: (state, action: PayloadAction<IClient>) => {
      state.client = action.payload;
    },
    setStoreClients: (state, action: PayloadAction<Array<IClient>>) => {
      state.clients = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      state.clients = action.payload;
    });
  },
});

export const { setStoreClient, setStoreClients } = clientSlice.actions;
export default clientSlice.reducer;
