import { Button } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import { useState } from "react";
import IExercise from "../../../interfaces/workouts/IExercice";
import ExerciseModal from "./ExerciseModal";
import { MdAdd } from "react-icons/md";

interface props {
  className?: string;
  addExercice: (exercice: IExercise) => void;
}
export default function CreateExerciseButton({
  className,
  addExercice,
}: props) {
  const { user, translate } = UseAppContext();
  const isProfessor = user.type === "professor";
  const [show, setShow] = useState(false);
  const exercise = {} as IExercise;

  if (!isProfessor || exercise?.done) {
    return null;
  }

  function openModal() {
    setShow(true);
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Button
        size="sm"
        className={"d-flex " + (className ? className : "")}
        onClick={(e) => {
          e.stopPropagation();
          openModal();
        }}
      >
        <MdAdd className="m-auto me-2" /> {translate("addExercise")}
      </Button>
      <ExerciseModal
        show={show && !exercise?.done}
        exerciseProp={exercise}
        hide={() => {
          setShow(false);
        }}
        updateExercice={addExercice}
      />
    </div>
  );
}
