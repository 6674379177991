import AppContainer from "../containers/AppContainer";
import SubcriptionDashboard from "../components/user/subcriptions/SubcriptionDashboard";

export default function SubscriptionPage() {
  return (
    <AppContainer>
      <div className="py-3 px-2">{<SubcriptionDashboard />}</div>
    </AppContainer>
  );
}
