import { Button } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import IWeek from "../../../interfaces/workouts/IWeek";
import WorkoutDay from "./WorkoutDay";
import { useState } from "react";
import IDay from "../../../interfaces/workouts/IDay";
import { variant } from "../../../functions/workoutsFunctions";
import { FaCheck } from "react-icons/fa";

interface props {
  week: IWeek;
  weeks: Array<IWeek>;
  selectedWeek: number;
}

export default function WorkoutWeek({ week, weeks, selectedWeek }: props) {
  const { translate } = UseAppContext();
  const [selectedDay, setSelectedDay] = useState(0);
  const dayToShow = week?.days ? week?.days[selectedDay] : ({} as IDay);

  if (!week) {
    return <></>;
  }

  return (
    <div className="mt-1">
      <div className="text-start">
        <span>{translate("day")}: </span>
        {week?.days?.map((day, position) => (
          <Button
            size="sm"
            style={{ minWidth: "35px" }}
            variant={
              position === selectedDay
                ? variant(day.done)
                : "outline-" + variant(day.done)
            }
            key={"day" + position + day.id}
            className={"mx-1"}
            onClick={() => {
              setSelectedDay(selectedDay === position ? -1 : position);
            }}
          >
            {position + 1}
            {day.done && <FaCheck size={7} className="ms-1" />}
          </Button>
        ))}
      </div>
      {selectedDay > -1 && (
        <WorkoutDay
          day={dayToShow}
          dayPosition={selectedDay}
          week={selectedWeek}
          weeks={weeks || []}
          key={`workout-day-${dayToShow?.position}-${dayToShow?.title?.slice(
            0,
            4
          )}`}
        />
      )}
    </div>
  );
}
