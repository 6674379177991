import { useSelector } from "react-redux";
import { useState } from "react";
import { UseAppContext } from "../../context/appContext";
import IProfile from "../../interfaces/users/IProfile";
import { Button, Image } from "react-bootstrap";
import ProfileModal from "./ProfileModal";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaUser } from "react-icons/fa";

export default function ProfileCard() {
  const { translate, user } = UseAppContext();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const isProfessor = user.type === "professor";

  const profile = useSelector(
    (state: any) => state.user.personalProfile
  ) as IProfile;

  function goToCheckout() {
    navigate("/checkout");
  }

  return (
    <div className="border rounded p-2 bg-white">
      <Image width={96} src={profile.pic} roundedCircle />

      <h5 className="mt-1 mb-0">{profile.name}</h5>
      <h6> {translate(`checkout.${user.plan}`)}</h6>

      <div>
        <Button
          onClick={() => {
            setShow(true);
          }}
          size="sm"
          className="mt-1 me-1"
        >
          <FaUser className="me-2 my-auto" size={10} />
          {translate("editProfile")}
        </Button>

        {isProfessor && (
          <Button
            onClick={() => {
              goToCheckout();
            }}
            size="sm"
            className="mt-1 ms-1"
          >
            {" "}
            <FaEdit className="me-2 my-auto" />
            {translate("editPlan")}
          </Button>
        )}
      </div>

      {!isProfessor && (
        <Button
          onClick={() => {
            navigate("/checkout");
          }}
          variant="outline-primary"
          size="sm"
          className="mt-1"
        >
          {translate("imProfessor")}
        </Button>
      )}

      <ProfileModal
        show={show}
        hide={() => {
          setShow(false);
        }}
      />
    </div>
  );
}
