import CheckoutForm from "../components/checkout/CheckoutForm";
import AppContainer from "../containers/AppContainer";

export default function Checkout() {
  return (
    <AppContainer>
      <div className="p-2">
        <CheckoutForm />
      </div>
    </AppContainer>
  );
}
