import ClientsList from "../components/clients/ClientsLists";
import AppContainer from "../containers/AppContainer";

export default function Clients() {
  return (
    <AppContainer>
      <div className="p-2">
        <ClientsList />
      </div>
    </AppContainer>
  );
}
